import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'
import ReactHelper from "../../helpers/ReactHelper";

const aboutImg = require('./images/about.svg')

const ITEMS = [
    {
        image: require('./images/ic_01.svg'),
        name: 'Занятия и вебинары по видеосвязи.'
    },
    {
        image: require('./images/ic_02.svg'),
        name: 'Курсы и упражнения доступны в любой точке мира 24/7.'
    },
    {
        image: require('./images/ic_03.svg'),
        name: 'Интерактивная доска, совместный анализ партий.'
    },
    {
        image: require('./images/tour-contest.svg'),
        name: 'Успеваемость. Профиль ученика.'
    }
];

const LAPTOPS = [
    {
        image: require('./images/zz_1.png')
    },
    {
        image: require('./images/zz_2_.png')
    },
    {
        image: require('./images/zz_3.png')
    }
];

export default function AboutSection(props) {
    const [activeLaptopNumber, setActiveLaptopNumber] = useState(0);
    const [autoEnabled, setAutoEnabled] = useState(true);

    let activeLaptop = LAPTOPS[activeLaptopNumber % LAPTOPS.length];

    let num = activeLaptopNumber % LAPTOPS.length;

    ReactHelper.useInterval(() => {
        if (autoEnabled == true) {
            setActiveLaptopNumber(+activeLaptopNumber + 1);
        }
    }, 3000);

    return (
        <Wrapper id={'about'}>

            <InnerPlaceholder2 className={'section-about__body'}>
                <Heading>
                    Онлайн-платформа ШАГИ CHESS
                </Heading>


                <TopPlaceholder>
                    <MainText>
                        {/*ШАГИ.online - это настоящая онлайн-академия. Ее основатель, один из лучших шахматистов России,*/}
                        {/*чемпион Европы Эрнесто Инаркиев.*/}
                        {/*Стройная система обучения: за 4 года ученик может пройти путь от начинающего шахматиста до*/}
                        {/*мастера*/}
                        Собственный интернет-портал делает обучение более интересным и эффективным.

                        <TripletsPlaceholder>
                            <ItemsPlaceholder>
                                {ITEMS.map((a, i) => {
                                    return (
                                        <Item key={`a_${i}`}>
                                            <ItemInner>
                                                <ImagePlaceholder className={`rel_im_it el_${i}`}>
                                                    <ItemImage src={a.image}/>
                                                </ImagePlaceholder>
                                                <ItemName>
                                                    {a.name}
                                                </ItemName>
                                                <ItemText>
                                                    {a.text}
                                                </ItemText>
                                            </ItemInner>
                                        </Item>
                                    )
                                })}
                            </ItemsPlaceholder>
                        </TripletsPlaceholder>

                    </MainText>

                    <RightPlaceholder>
                        <LaptopPlaceholder>
                            <LaptopImg
                                key={`aas_${activeLaptopNumber}`}
                                src={activeLaptop.image}
                                onClick={() => {
                                    setActiveLaptopNumber(+activeLaptopNumber + 1);
                                    setAutoEnabled(false);
                                }}/>
                            <SubLaptopCirclesPlaceholder>
                                {LAPTOPS.map((a, i) => {
                                    let isSelected = (num == i);
                                    return (
                                        <Circle
                                            key={i}
                                            selected={isSelected} onClick={() => {
                                            setActiveLaptopNumber(i);
                                            setAutoEnabled(false);
                                        }}>

                                        </Circle>
                                    )
                                })}
                            </SubLaptopCirclesPlaceholder>
                        </LaptopPlaceholder>
                    </RightPlaceholder>

                </TopPlaceholder>


            </InnerPlaceholder2>


        </Wrapper>
    );
}

const breakWidth = 1100;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media(max-width: 920px){
      display: block;
    }
`;

const RightPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 30px;
    position: relative;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const LaptopPlaceholder = styled.div`
    position: absolute;
    width: calc(50vw - 50px - 50px);
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media(max-width: 920px){
      width: 100%;
      position: relative;
    }
`;

const SubLaptopCirclesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
`;

const Circle = styled.div`
    width: 16px;
    height: 16px;
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    background: ${props => (props.selected ? '#FF624F' : 'transparent')};
    border-radius: 1000px;
    border: 2px solid #86d1ff;
    box-sizing: border-box;
    
    margin-left: 5px;
    margin-right: 5px;
    
`;

const LaptopImg = styled.img`
    width: 100%;  
`;

const Item = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
    @media(max-width: 720px){
      width: 100%;
      display: block;
    }
`;


const ItemImage = styled.img`
    height: 80px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
`;


const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 120px;
    @media(max-width: 720px){
      margin-left: -60px;
    }
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
    @media(max-width: 720px){
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;

const ItemName = styled.div`
    font-weight: 800;
    line-height: 1.25;
    font-size: 20px;
    margin: 0 0 20px;
`;


const ItemText = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    color: #000;
`;

const ItemsPlaceholder = styled.div`
    
`;

const TripletsPlaceholder = styled.div`
    width: 600px;
    margin-top: 40px;
    @media(max-width: 620px){
      width: 100%;
    }
`;

const MainText = styled.div`
    font: 600 20px/1.56 "Open Sans",sans-serif;
    color: #000;
    width: 600px;
    @media(max-width: 812px){
      width: 100%;
      text-align: center;
      font-size: 16px;
    }
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;  
    position: relative;
    ::before{
        content: '';
        width: 1em;
        height: 1em;
        position: absolute;
        top: -96px;
        right: -154px;
        z-index: -1;
        background-image: url(${aboutImg});
        background-size: cover;
        font-size: 146px;
        transform: rotate(30deg);
    }
`;

const InnerPlaceholder2 = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;  
    position: relative;
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 20px;
    }
`;

const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: left;
     @media(max-width: 812px){
      text-align: center;
      font-size: 26px;
      margin-bottom: 14px;
     }
`;
