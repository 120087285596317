import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import axios from 'axios'

import LocationHelper from '../../helpers/LocationHelper'

import AnalyticsAPI from '../../api/AnalyticsAPI'

// const API_ENDPOINT = 'https://chess-prod-api.sabir.pro';
const API_ENDPOINT = 'https://api.cloud1.shagi.online';

const ie = a => (a == undefined || `${a}`.replace(/ /g, '') == '');

// function validateEmail(email) {
//     const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(String(email).toLowerCase());
// }

const getErrorMessage = (d) => {
    // let {name, email, phone} = d;
    let {name, phone} = d;
    if (ie(name) || name.length < 2) {
        return `Пожалуйста, укажите имя`;
    }
    if (ie(phone) || phone.length < 5) {
        return `Пожалуйста, укажите корректный телефон`;
    }
    // if (ie(email) || validateEmail(email) == false) {
    //     return `Пожалуйста, укажите корректный email`;
    // }
    return undefined;
}

export default function UpdateLeadForm(props) {
    const {
        hasHeading = true,
        buttonName = 'ЗАПИСАТЬСЯ',

        googleActionName = 'Send_Form',
        yandexActionName = 'Send_Form'

    } = props;

    const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    // const [sent, setSent] = useState(true);

    const [error, setError] = useState(undefined);

    return (
        <Wrapper>

            {hasHeading == false || sent == true ? null :
                <React.Fragment>
                    <InnerHeading>
                        Бесплатное занятие
                    </InnerHeading>

                    <SubInnerHeading>
                        {/*Оставьте свои контакты и мы с Вами свяжемся*/}
                        Позвоните нам <InlinePhoneLink
                        href={`tel:${LocationHelper.getPhone()}`}
                        onClick={() => {
                            AnalyticsAPI.trackFormPhoneClick();
                        }}
                    >{LocationHelper.getPhoneLabel()}</InlinePhoneLink> или оставьте заявку, и мы Вам позвоним.
                    </SubInnerHeading>
                </React.Fragment>
            }

            {sent == true ? null :
                <React.Fragment>
                    <Field>
                        <Input placeholder={'Имя'} value={name} onChange={evt => {
                            setName(evt.target.value);
                            setError(undefined);
                        }}/>
                    </Field>
                    <Field>
                        <Input placeholder={'Телефон'} value={phone} onChange={evt => {
                            setPhone(evt.target.value);
                            setError(undefined);
                        }}/>
                    </Field>
                    {/*<Field>*/}
                    {/*<Input placeholder={'Email'} value={email} onChange={evt => {*/}
                    {/*setEmail(evt.target.value);*/}
                    {/*setError(undefined);*/}
                    {/*}}/>*/}
                    {/*</Field>*/}
                </React.Fragment>
            }

            {sent == false ? null :
                <SentPlaceholder>
                    {`Спасибо!`}
                    <br/>
                    {`Мы с Вами свяжемся по указанным контактам`}
                    <br/>
                    {`${phone}`}
                    <br/>
                    <br/>
                    {/*+7 903 559-44-74*/}
                    <WhatsLink target={'_blank'}
                               href={`https://api.whatsapp.com/send?phone=${LocationHelper.getWhatsapPhone().replace('+', '')}&text=Добрый день!`}>
                        Написать нам в Вотсап
                    </WhatsLink>

                </SentPlaceholder>
            }

            {error == undefined ? null :
                <SentPlaceholder style={{color: 'red'}}>
                    {error}
                </SentPlaceholder>
            }

            {sent == true ? null :
                <ButtonPlaceholder>
                    {loading == true ? null :
                        <RedButton onClick={async () => {
                            let msg = getErrorMessage({phone, name});
                            if (msg != undefined) {
                                setError(msg);
                                return;
                            }

                            setLoading(true);

                            let url_ = ``;
                            let cookieString = '';
                            try {
                                url_ = window.location.href;
                            } catch (exc1) {

                            }
                            try {
                                cookieString = document.cookie;
                            } catch (exc1) {

                            }
                            await axios.post(`${API_ENDPOINT}/lead`, {
                                phone,
                                name,
                                url: url_,
                                email: '',
                                cookieString: cookieString
                            }, {
                                params: {
                                    spb: (window.location.href.indexOf('spb') > -1) ? 'yes' : 'no'
                                }
                            });
                            setLoading(false);
                            setSent(true);
                            try {
                                AnalyticsAPI.pixelTrackLead();

                                // AnalyticsAPI.reachGoal('lead');
                                AnalyticsAPI.reachGoal(yandexActionName);
                                AnalyticsAPI.googleTrackEvent('button', googleActionName);

                                AnalyticsAPI.googleTrackEvent('button', 'Send_Form');

                            } catch (e) {

                            }
                        }}>
                            {buttonName}
                        </RedButton>
                    }
                </ButtonPlaceholder>
            }

            {sent == true ? null :
                <AccPlaceholder>

                    {/*Нажимая, вы принимаете*/}
                    {/*{' '}*/}
                    {/*<AccLink target={'_blank'} style={{marginRight: 5}} href={`/oferta.pdf`}>*/}
                    {/*условия соглашения*/}
                    {/*</AccLink>*/}
                    {/*{' и '}*/}
                    {/*<AccLink target={'_blank'} href={`/conf.pdf`}>*/}
                    {/*политику конфиденциальности*/}
                    {/*</AccLink>*/}

                    Принимаю
                    <AccLink target={'_blank'} style={{marginRight: 5, marginLeft: 5}} href={`/conf.pdf`}>
                        Политику обработки персональных данных
                    </AccLink>
                    и выражаю согласие на обработку персональных данных. {' '}
                    Подтверждаю, что ознакомлен с условиями
                    <AccLink target={'_blank'} style={{marginLeft: 5}} href={`/oferta.pdf`}>
                        Договора-оферты
                    </AccLink>.


                </AccPlaceholder>
            }


        </Wrapper>
    );
}

const WhatsLink = styled.a`
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 1000px;
  color: white;
  background: #1ebea5;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    opacity: 0.8;
    color: white;
  }
`;

const AccPlaceholder = styled.div`
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  text-align: center;
  padding: 5px;
  opacity: 0.3;
  font-size: 12px;
  margin-top: 12px;
`;

const AccLink = styled.a`
  margin-left: 5px;
`;

const SentPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  text-align: center;
`;

const Wrapper = styled.div`
  //min-width: 500px;
  width: 100%;
`;

const RedButton = styled.div`
  height: 50px;
  border-radius: 1000px;
  background: #FF624F;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 26px;
  padding-left: 35px;
  padding-right: 35px;

  :hover {
    opacity: 0.95;
  }

  @media (max-width: 720px) {
    height: 40px;
    font-size: 16px;
  }

  :hover {
    opacity: 0.8;
  }
`;

const InnerHeading = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  @media (max-width: 812px) {
    font-size: 24px;
    padding-top: 38px;
  }
`;

const SubInnerHeading = styled.div`
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
  font-style: italic;
  margin-bottom: 20px;
  @media (max-width: 812px) {
    font-size: 14px;
  }
`;

const InlinePhoneLink = styled.a`
  color: black;
  text-decoration: underline;
  margin-left: 4px;
  margin-right: 4px;
`;

const BlackButton = styled.div`
  background: black;
  height: 60px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 35px;
  padding-right: 35px;
  cursor: pointer;

  :hover {
    opacity: 0.95;
  }

  @media (max-width: 720px) {
    height: 40px;
    font-size: 16px;
  }
`;


const Field = styled.div`
  margin-bottom: 20px;
  @media (max-width: 720px) {
    margin-bottom: 10px;
  }
`;

const ButtonPlaceholder = styled.div`

`;

const Input = styled.input`
  box-sizing: border-box;
  outline: none;
  width: 100%;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.47);
  background: white;
  padding-left: 5px;
  height: 50px;
  font-size: 24px;

  :focus {
    border-color: #ffc02c;
  }

  @media (max-width: 720px) {
    height: 40px;
    font-size: 18px;
  }
`;
