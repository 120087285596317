import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'

const ITEMS = [
    {
        image: require('./images/academy-meta-city.svg'),
        name: '20',
        text: 'стран'
    },
    {
        image: require('./images/academy-meta-school.svg'),
        name: '213',
        text: 'тренеров'
    },
    {
        image: require('./images/academy-meta-students.svg'),
        name: '3750',
        text: 'учеников'
    }
]

export default function BenefitsSection(props) {


    return (
        <Wrapper>

            <InnerPlaceholder>

                <Heading>
                    Академия в цифрах
                </Heading>

                <ItemsPlaceholder>
                    {ITEMS.map((a, i) => {
                        return (
                            <Item key={`a_${i}`}>
                                <ItemInner>
                                    <ItemImage src={a.image} />
                                    <ItemName>
                                        {a.name}
                                    </ItemName>
                                    <ItemText>
                                        {a.text}
                                    </ItemText>
                                </ItemInner>
                            </Item>
                        )
                    })}
                </ItemsPlaceholder>

            </InnerPlaceholder>


        </Wrapper>
    );
}

const breakWidth = 1100;

const ItemImage = styled.img`
    height: 120px;
`;

const Item = styled.div`
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
`;

const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 120px;
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const ItemName = styled.div`
    font-weight: 800;
    line-height: 1.25;
    font-size: 65px;
    margin-top: 30px;
`;

const ItemText = styled.div`
    font: 600 26px/1.56 "Open Sans",sans-serif;
    color: #000;
    font-weight: bold;
`;

const ItemsPlaceholder = styled.div`
    
`;


const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 20px;
    }
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: center;
`;

