import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'
import NiceModal from "../modals/NiceModal";
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import AnalyticsAPI from "../api/AnalyticsAPI";
import LocationHelper from "../helpers/LocationHelper";

const ernImg = require('./images/opt/ern_opt.jpg')
const tileImg = require('./images/blue_tile.svg')


export default function ErnSection(props) {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <Wrapper>

            <TopHeading>
                Наша команда
            </TopHeading>

            <InnerPlaceholder className={'section-about__body'}>

                <TopPlaceholder>

                    <Right>
                        <ErnImg/>
                    </Right>

                    <Left>
                        <Heading>
                            Эрнесто Инаркиев
                        </Heading>
                        <SubHeading>
                            Основатель, главный тренер
                        </SubHeading>

                        <PText>
                            {/*За 4 года прошел путь от начинающего до мастера.*/}
                            {/*В 11 лет стал мастером спорта, а в 16 международным гроссмейстером.*/}
                            {/*С 18 лет входит в 100 лучших игроков мира. Один из лучших шахматистов России.*/}
                            {/*Лучшее достижение в рейтинге ФИДЕ 2732 и 25-й в мире по классическим шахматам на 01.10.2016.*/}
                            {/*Чемпион Европы 2016 г.*/}
                            За 4 года прошел путь от начинающего до мастера.
                            В 11 лет стал мастером спорта, а в 16 международным гроссмейстером.
                            С 18 лет входит в 100 лучших игроков мира. Один из лучших шахматистов России.
                            Чемпион Европы 2016 г. Лучшее достижение в рейтинге ФИДЕ 2732 и <b>25-й в мире по
                            классическим шахматам</b> на 01.10.2016, 2767 и <b>15-й в мире по быстрым шахматам</b> на
                            01.12.2017.

                        </PText>

                        <BottomButtonPlaceholder>
                            <RedButton onClick={() => {
                                // window.location.href = `/app/`;
                                AnalyticsAPI.googleTrackEvent('button', 'trial_lesson_how_training');
                                AnalyticsAPI.reachGoal('button_trial_lesson_how_training');
                                // setModalVisible(true);
                                LocationHelper.navigateToLeadForm();
                            }}>
                                ЗАПИСАТЬСЯ НА ПРОБНОЕ ЗАНЯТИЕ
                                {/*ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ*/}
                            </RedButton>
                        </BottomButtonPlaceholder>

                    </Left>

                </TopPlaceholder>

            </InnerPlaceholder>

            {modalVisible == false ? null :
                <NiceModal onClose={() => {
                    setModalVisible(false);
                }}>

                    <UpdateLeadForm
                        googleActionName={'team'}
                        yandexActionName={'button_team'}
                    />

                </NiceModal>
            }

        </Wrapper>
    );
}

const breakWidth = 1100;

const RedButton = styled.div`
    height: 44px;
    border-radius: 1000px;
    background: #FF624F;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
    }
`;

const TopHeading = styled.h2`
     font-size: 35px;
     margin-bottom: 84px;
     text-align: center;
`;


const Left = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding-left: 120px;
    padding-top: 80px;
    @media(max-width: 920px){
      padding-left: 0px;
      padding-top: 30px;
      text-align: center;
    }
`;


const PText = styled.div`
    
`;

const BottomButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 60px;
    @media(max-width: 720px){
      justify-content: center;
    }
`;

const BlackButton = styled.div`
    background: black;
    height: 50px;
    border-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;


const Right = styled.div`
    flex: 1;
    position: relative;
    min-height: 700px;
    ::before{
      content: '';
        width: 1em;
        height: 1em;
        position: absolute;
        z-index: 1;
        background-size: cover;
        background-image: url(${tileImg});
        will-change: transform;
        right: -45px;
        transform: rotate(-8deg);
        font-size: 588px;
        @media(max-width: 820px){
          font-size: 488px;
        }
    }
    @media(max-width: 820px){
      min-height: 500px;
    }
`;

const ErnImg = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${ernImg});
    width: 100%;
    height: 675px;
    border-radius: 20px;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    
    @media(max-width: 820px){
      width: calc(100% - 40px);
      height: 488px;
      margin-left: 20px;
    }
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media(max-width: 920px){
      display: block;
    }
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;  
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 10px;
    }
`;

const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 14px;
     text-align: left;
     @media(max-width: 820px){
      text-align: center;
    }
`;

const SubHeading = styled.h3`
     font-weight: 800;
    line-height: 1.25;
    font-size: 20px;
    margin: 0 0 20px;
    text-align: left;
    @media(max-width: 820px){
      text-align: center;
    }
`;
