import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import ReactHelper from "../../helpers/ReactHelper";

export default function NiceModal(props) {
    const {
        onClose = () => {

        },
        zIndex = 100,
        closeOnClickOutside = true
    } = props;

    const ref = useRef();
    ReactHelper.useOnClickOutside(ref, () => {
        if (closeOnClickOutside) {
            onClose();
        }
    });

    return (
        <Wrapper zIndex={zIndex}>

            <Box ref={ref} >
                <TopBox>
                    <CloseCircle onClick={() => {
                        onClose();
                    }}>
                        ×
                    </CloseCircle>
                </TopBox>

                <ContentPlaceholder >
                    {props.children}
                </ContentPlaceholder>

            </Box>

        </Wrapper>
    );
}



const CloseCircle = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 1000px;
    background-color: #ffc02c;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #000;
    :hover{
      opacity: 0.8;
    }
`;

const Wrapper = styled.div`
    position: fixed;
    z-index: ${props => (props.zIndex == undefined ? 10 : props.zIndex)};
    top: 0px;    
    bottom: 0px;    
    left: 0px;    
    right: 0px;
    background: rgba(51,51,51, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    justify-content: center;
`;

const Box = styled.div`
    box-sizing: border-box;
    background: white;
    position: relative;
    width: 720px;
    padding: 70px;
    padding-left: 110px;
    padding-right: 110px;
    border-radius: 20px;
    max-height: 95vh;
    overflow-y: auto;
    @media(max-width: 812px){
      width: 100%;
      padding: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const TopBox = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
`;

const CloseIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

const ContentPlaceholder = styled.div`
    
`;
