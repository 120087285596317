import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'
import NiceModal from "../modals/NiceModal";
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import LocationHelper from "../helpers/LocationHelper";
import AnalyticsAPI from "../api/AnalyticsAPI";

export default function FooterSection(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [aboutVisible, setAboutVisible] = useState(false);

    return (
        <Wrapper>

            <FreeTop>
                <RedButton onClick={() => {
                    AnalyticsAPI.googleTrackEvent('button', 'free_lesson_partners');
                    AnalyticsAPI.reachGoal('button_free_lesson_partners');
                    // setModalVisible(true);
                    LocationHelper.navigateToLeadForm();
                }}>
                    ПРОБНОЕ ЗАНЯТИЕ
                </RedButton>
            </FreeTop>

            <TopPhonePlaceholder href={`tel:${LocationHelper.getPhone()}`}>
                {`${LocationHelper.getPhoneLabel()}`}
            </TopPhonePlaceholder>

            <InnerPlaceholder>

                <VeryInnerPlaceholder>
                    <div>
                        <div>
                            <Logo src={require('./images/logo.svg')}/>
                        </div>
                    </div>


                    <FCenterMobileNone>
                        <FRowLink href={'/app/'}>
                            Портал
                        </FRowLink>
                        <FRowLink href={'/oferta.pdf'}>
                            Пользователькое
                            <br/>
                            соглашение
                        </FRowLink>
                        <FRowLink href={'/conf.pdf'}>
                            Политика
                            <br/>
                            конфиденциальности
                        </FRowLink>
                        <FRowLink href={'/payment/'}>
                            Об оплате
                        </FRowLink>
                        <FRowLink href={'javascript: void(0);'} onClick={() => {
                            setAboutVisible(true);
                        }}>
                            Об организации
                        </FRowLink>
                    </FCenterMobileNone>

                    <FCenterMobileNone>



                        <FRowLink href={'https://vk.com/shagichess'}>
                            Вконтакте
                        </FRowLink>
                        <FRowLink href={'https://www.youtube.com/channel/UC0vjFaD30lbRo_3EgMxRjOA'}>
                            YouTube
                        </FRowLink>
                    </FCenterMobileNone>

                    <FCenter>

                        <PaykeeperSection>
                            <PkImage src={require('./images/logo3h.png')}/>
                        </PaykeeperSection>

                    </FCenter>
                </VeryInnerPlaceholder>

            </InnerPlaceholder>

            {modalVisible == false ? null :
                <NiceModal onClose={() => {
                    setModalVisible(false);
                }}>

                    <UpdateLeadForm
                        googleActionName={'partners'}
                        yandexActionName={'button_partners'}
                    />

                </NiceModal>
            }

            {aboutVisible == false ? null :
                <NiceModal onClose={() => {
                    setAboutVisible(false);
                }}>

                    <P>
                        Общество с ограниченной ответственностью “ШАХМАТНАЯ АКАДЕМИЯ ГРОССМЕЙСТЕРА ИНАРКИЕВА
                        “ШАГИ.ОНЛАЙН”
                    </P>

                    <P>
                        ИНН 7714452020
                    </P>
                    <P>
                        ОГРН 1197746557788
                    </P>
                    <P>
                        Телефон: +7 (903) 559-37-70
                    </P>
                    <P>
                        e-mail: office@shagichess.ru
                    </P>
                    <P>
                        123592, г. Москва, улица Кулакова 12 корпус 1, квартира 239.
                    </P>


                </NiceModal>
            }


        </Wrapper>
    );
}

const FreeTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const P = styled.p`
    
`;

const TopPhonePlaceholder = styled.a`
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 44px;
    line-height: 70px;
    display: block;
    text-decoration: none;
    color: black;
    @media(max-width: 812px){
      font-size: 32px;
      line-height: 60px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
`;

const PaykeeperSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    @media(max-width: 812px){
      display: none;
    }
`;

const PkImage = styled.img`
    width: calc(100% - 40px);
`;

const RedButton = styled.div`
    height: 44px;
    border-radius: 1000px;
    background: #FF624F;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const breakWidth = 1100;

const ItemImage = styled.img`
    height: 160px;
    border-radius: 1000px;
`;

const Item = styled.div`
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
`;

const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 120px;
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const ItemName = styled.div`
    font-weight: 800;
    line-height: 1.25;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
`;

const ItemText = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    font-weight: normal;
    font-style: italic;
    color: #939598;
    text-align: center;
`;

const ItemsPlaceholder = styled.div`
    
`;

const Logo = styled.img`
    height: 40px;
    @media(max-width: 812px){
      display: none;
    }
`;

const FCenter = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const FCenterMobileNone = styled(FCenter)`
    @media(max-width: 812px){
        display: none;
    }
`;

const FRow = styled.div`
    margin-bottom: 10px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
      text-decoration: underline;
    }
`;

const FRowLink = styled.a`
    margin-bottom: 10px;
    cursor: pointer;
    color: black;
    border-bottom: none;
    text-decoration: none;
    :hover{
      opacity: 0.8;
      text-decoration: underline;
    }
`;


const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
    @media(max-width: 812px){
      margin-top: 00px;
      padding-top: 00px;
    }
`;

const VeryInnerPlaceholder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media(max-width: 920px){
      display: block;
      text-align: center;
    }
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 20px;
    }
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: center;
`;

