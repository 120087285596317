import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import NiceModal from "../modals/NiceModal";
import AnalyticsAPI from "../api/AnalyticsAPI";
import LocationHelper from "../helpers/LocationHelper";

const ITEMS = [
    {
        image: require('./images/sv-plan.svg'),
        topText: '48',
        bottomText: 'месяцев',

        text: 'Путь от начинающего до мастера.\n'
    },
    {
        image: require('./images/sv-plan.svg'),

        topText: '20 000 ',
        bottomText: 'часов',

        text: 'Провел Эрнесто Инаркиев изучая шахматы и выступая в соревнованиях. \n'
    },
    {
        image: require('./images/sv-plan.svg'),

        topText: '222',
        bottomText: 'месяца',

        text: 'Наша мечта  - за этот срок помочь одному из наших учеников стать чемпионом мира.'
    },

]

export default function SubTopSection(props) {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <Wrapper>

            <InnerPlaceholder>

                <Heading>
                    Шахматная Aкадемия Гроссмейстера Инаркиева
                </Heading>

                <MainText>
                    Основатель - чемпион Европы, один из лучших игроков России.
                    <br/>
                    Собственный интернет-портал специально разработанный для занятий шахматами.
                    <br/>
                    Целеустремленная команда.

                </MainText>

                <ItemsPlaceholder>
                    {ITEMS.map((a, i) => {
                        return (
                            <Item key={`a_${i}`}>
                                <ItemInner>
                                    <ImagePlaceholder className={`rel_im_it_x el_${i}`}>
                                        {/*<ItemImage src={a.image} />*/}
                                        <ItemTextSup>
                                            <TopItemText>
                                                {a.topText}
                                            </TopItemText>
                                            <BottomItemText>
                                                {a.bottomText}
                                            </BottomItemText>
                                        </ItemTextSup>
                                    </ImagePlaceholder>
                                    {/*<ItemName>*/}
                                    {/*{a.name}*/}
                                    {/*</ItemName>*/}
                                    <ItemText>
                                        {a.text}
                                    </ItemText>
                                </ItemInner>
                            </Item>
                        )
                    })}
                </ItemsPlaceholder>

            </InnerPlaceholder>

            <BottomButtonPlaceholder>
                <RedButton onClick={() => {
                    // setModalVisible(true);
                    AnalyticsAPI.googleTrackEvent('button', 'trial_lesson');
                    AnalyticsAPI.reachGoal('button_trial_lesson');
                    LocationHelper.navigateToLeadForm();
                }}>
                    {/*БЕСПЛАТНОЕ ПРОБНОЕ ЗАНЯТИЕ*/}
                    {/*ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ*/}
                    ЗАПИСАТЬСЯ НА ПРОБНОЕ ЗАНЯТИЕ
                </RedButton>
            </BottomButtonPlaceholder>

            {modalVisible == false ? null :
                <NiceModal onClose={() => {
                    setModalVisible(false);
                }}>

                    <UpdateLeadForm
                        googleActionName={'numbers'}
                        yandexActionName={'button_numbers'}
                    />

                </NiceModal>
            }


        </Wrapper>
    );
}

const MainText = styled.div`
  font: 600 20px/1.56 "Open Sans", sans-serif;
  color: #000;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 812px) {
    font-size: 18px;
  }
`;


const RedButton = styled.div`
  height: 44px;
  border-radius: 1000px;
  background: #FF624F;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const BottomButtonPlaceholder = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 812px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const breakWidth = 1100;

const ItemImage = styled.img`
  height: 120px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
`;

const ItemTextSup = styled.div`
  height: 120px;
  position: absolute;
  top: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 43px;
  width: 260px;

  left: -130px;
  text-align: center;
`;

const TopItemText = styled.div`
  font-size: 65px;
`;

const BottomItemText = styled.div`
  font-size: 32px;
  text-align: center;
`;

const Item = styled.div`
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  @media (max-width: 820px) {
    width: 50%;
  }
  @media (max-width: 680px) {
    width: 100%;
  }
`;

const ImagePlaceholder = styled.div`
  margin-bottom: 30px;
  position: relative;
  height: 120px;
  font-size: 60px;
  font-weight: bold;
`;

const ItemInner = styled.div`
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

const ItemName = styled.div`
  font-weight: 800;
  line-height: 1.25;
  font-size: 20px;
  margin: 0 0 20px;
`;

const ItemText = styled.div`
  font: 600 16px/1.56 "Open Sans", sans-serif;
  color: #000;
  text-align: center;
`;

const ItemsPlaceholder = styled.div`

`;


const Wrapper = styled.div`
  width: 100vw;
  box-sizing: border-box;
  background: white;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 812px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
    padding: 20px;
  }
  @media (max-width: 800px) {
    padding-top: 5px;
  }
`;

const Heading = styled.h2`
  font-size: 35px;
  margin-bottom: 44px;
  text-align: center;
  @media (max-width: 812px) {
    font-size: 22px;
    margin-bottom: 24px;
  }
`;

