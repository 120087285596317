import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import axios from 'axios'

import './style.css'
import NiceModal from "../modals/NiceModal";

const aboutImg = require('./images/about.svg')

const ITEMS = [
    {
        name: 'Название мероприятия',
        level: '1200-1400',
        date: '12 мая в 18:00',
        author: 'Эрнесто Инаркиев',
        price: '8420',
        image: require('./images/kn-c.jpg')
    },
    {
        name: 'Название мероприятия',
        level: '1200-1400',
        date: '12 мая в 18:00',
        author: 'Эрнесто Инаркиев',
        price: '8420',
        image: require('./images/kn-c.jpg')
    },
    {
        name: 'Название мероприятия',
        level: '1200-1400',
        date: '12 мая в 18:00',
        author: 'Эрнесто Инаркиев',
        price: '8420',
        image: require('./images/kn-c.jpg')
    },
]

export default function WebinarSection(props) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get('https://chess-prod-api.sabir.pro/key/landing_webinar_items').then(d => d.data.result).then(a => {
            console.log('landing_webinar_items: a = ', a);
            let arr = (a == undefined) ? [] : a;
            setItems(arr);
            setLoading(false);
        });
    }, []);

    return (
        <Wrapper id={'webinars'} >

            <InnerPlaceholder className={'section-about__body'}>
                <Heading>
                    Вебинары
                    Шахматной Академии
                    "ШАГИ CHESS"
                </Heading>
                <SubHeading>
                    Раскрывают мысли гроссмейстеров о важных навыках мышления, компонентах мастерства.
                    {' '}
                    Живое общение позволяет сравнить свое восприятие с восприятием гроссмейстеров, и понять, на что
                    следует обратить внимание.
                    {' '}
                    Специальные упражнения на нашей онлайн-платформе закрепляют полученные знания.
                </SubHeading>

                <TripletsPlaceholder>
                    <ItemsPlaceholder>
                        {items.map((a, i) => {
                            return (
                                <Item key={`a_${i}`} className={'course-tile'}>
                                    <ItemTopImage src={a.avatar}/>
                                    <ItemCont>
                                        <ItemName>
                                            {a.name}
                                        </ItemName>
                                        <ItemRow>
                                            <RowImg src={require('./images/c-rating.svg')}/>
                                            <ItValue>
                                                <ItLabel>
                                                    Уровень:
                                                </ItLabel>
                                                <div>
                                                    {a.level}
                                                </div>
                                            </ItValue>
                                        </ItemRow>
                                        <ItemRow>
                                            <RowImg src={require('./images/c-cal.svg')}/>
                                            <ItValue>
                                                <ItLabel>
                                                    Дата:
                                                </ItLabel>
                                                <div>
                                                    {a.date}
                                                </div>
                                            </ItValue>
                                        </ItemRow>
                                        <ItemRow>
                                            <RowImg src={require('./images/c-hat.svg')}/>
                                            <ItValue>
                                                <ItLabel>
                                                    Автор:
                                                </ItLabel>
                                                <div>
                                                    {a.author}
                                                </div>
                                            </ItValue>
                                        </ItemRow>
                                        <ItemBottomPlaceholder>
                                            <ItemPrice>
                                                {`${a.price} ₽`}
                                            </ItemPrice>
                                            <BlueButton onClick={() => {
                                                window.location.href = `https://webinar.shagi.online`;
                                            }}>
                                                Записаться
                                            </BlueButton>
                                        </ItemBottomPlaceholder>
                                    </ItemCont>
                                </Item>
                            )
                        })}
                    </ItemsPlaceholder>
                </TripletsPlaceholder>


            </InnerPlaceholder>

        </Wrapper>
    );
}

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 15px;
     text-align: center;
`;

const SubHeading = styled.h3`
     font-size: 18px;
     text-align: center;
     font-weight: normal;
     font-style: italic;
     width: 760px;
     margin: 0 auto;
     opacity: 0.7;
     @media(max-width: 740px){
      width: 100%;
     }
`;


const breakWidth = 1100;

const ItemPrice = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FF624F;
`;

const BlueButton = styled.div`
    background: #085BFF;
    border-radius: 1000px;
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    :hover{
       opacity: 0.9;
    }
`;

const ItemCont = styled.div`
    padding: 27px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 90px;
`;

const ItemBottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ItemRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;

const ItValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #201F41;
`;

const ItLabel = styled.div`
    color: rgba(31, 32, 65, 0.5);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-right: 4px;
`;

const RowImg = styled.img`
    margin-right: 10px;
    height: 22px;
`;

const ItemName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #1F2041;
    margin-bottom: 20px;
    min-height: 90px;
`;

const Item = styled.div`
    text-align: left;
    width: calc(33.33% - 80px);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    //height: 400px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    margin: 20px;
    @media(max-width: 920px){
      width: calc(50% - 60px);
    }
    @media(max-width: 720px){
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }
`;

const ItemTopImage = styled.div`
    height: 230px;
    background-image: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

// const ItemBottomPlaceholder = styled.div`
//     box-sizing: border-box;
//     border-bottom-left-radius: 20px;
//     border-bottom-right-radius: 20px;
//     min-height: 100px;
//     padding: 20px;
//     font-weight: 800;
//     line-height: 1.25;
//     font-size: 20px;
//     margin-bottom: 18px;
//     background-color: ${props => props.bgColor};
//     height: 130px;
//     position: relative;
// `;


const ItemImage = styled.img`
    height: 80px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
`;


const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 120px;
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
`;


const ItemText = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    color: #000;
`;

const ItemsPlaceholder = styled.div`
    //display: flex;
    //flex-direction: row;
    //align-items: flex-start;
    //justify-content: center;
    text-align: center;
`;

const TripletsPlaceholder = styled.div`
    margin-top: 40px;
`;

const MainText = styled.div`
    font: 600 20px/1.56 "Open Sans",sans-serif;
    color: #000;
    width: 600px;
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    ::before{
        content: '';
        width: 1em;
        height: 1em;
        position: absolute;
        top: -96px;
        right: -154px;
        z-index: -1;
        background-image: url(${aboutImg});
        background-size: cover;
        font-size: 246px;
        transform: rotate(30deg);
    }
    @media(max-width: ${breakWidth}px){
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
    }
`;

const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
`;

