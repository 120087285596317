import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'
import NiceModal from "../modals/NiceModal";
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import AnalyticsAPI from "../api/AnalyticsAPI";
import LocationHelper from "../helpers/LocationHelper";

const aboutImg = require('./images/about.svg')

const ITEMS = [
    {
        googleActionName: 'initial_training',
        yandexActionName: 'banner_initial_training',
        image: require('./images/opt/c_-1.jpg'),
        selImage: require('./images/opt/cc-1.jpg'),
        name: 'Начальное обучение',
        selName: 'Начальное обучение',
        bgColor: '#86D1FF',
        tileColor: '#085BFF',
        content: `
        <ul>
            <li>
                Цикл занятий для подготовки к основному курсу. 
            </li>
            <li>
                Обучение азам и формирование общего представления о шахматах.
            </li> 
            <li>
                По окончании курса ученики могут играть и записывать партии.
            </li> 
            <li>
               Отдельные группы и программа обучения для детей 5-6 лет.
            </li>
        </ul>
        <p>
            Стоимость: Тренер КМС, абонемент на 8 занятий - 5000 руб.
        </p>
`
    },
    {
        googleActionName: 'academic_course_2',
        yandexActionName: 'banner_academic_course_2',
        image: require('./images/opt/c-2.jpg'),
        selImage: require('./images/opt/c-2.jpg'),
        name: 'Академический курс 2 года <br/> (рейтинг 1000-1700)',
        selName: 'Академический курс <br/> 2 года  (рейтинг 1000-1700)',
        bgColor: '#FF624F',
        tileColor: '#86D1FF',
        content: `
        <b>
            1-й год (1000-1300)
        </b>
        
        <br/> 
         <ul>
            <li>
                Развитие концентрации внимания.
            </li>
            <li>
                Удовольствие от мыслительного процесса, от логики и принятия обдуманных решений.        
            </li>
            <li>
                Базовые навыки анализа ситуации за счет тренировки простого расчета вариантов.            
            </li>
            <li>
                Развитие привычки к самостоятельной тренировке за счет выполнения домашнего задания.            
            </li> 
        </ul>
    
    <br/>
    <b>
        2-й год (1300-1700).
    </b> 
    <br/>
    <ul>
        <li>
            Усиление способности действовать в уме, за счет тренировки более сложного расчета вариантов.
        </li>
        <li>
            Развитие памяти за счет изучение дебютных вариантов на базовом уровне, и освоения начальных знаний по окончаниям.        
        </li>
        <li>
            Формирование спортивного характера, за счет повышения тренировочной нагрузки и повышения ответственности за выступления в соревнованиях.    
        </li>
    </ul>
    <p>
            Стоимость: Тренер КМС, абонемент на 8 занятий - 5000 руб.
        </p>
`
    },
    {
        googleActionName: 'academic_course_3',
        yandexActionName: 'banner_academic_course_3',
        image: require('./images/opt/cc-3.jpg'),
        selImage: require('./images/opt/cc-3.jpg'),
        name: 'Академический курс  3-й год <br/> (рейтинг 1700-2100)',
        selName: 'Академический курс  <br/> 3-й год (рейтинг 1700-2100)',
        bgColor: '#A36EFF',
        tileColor: '#FF66FF',
        content: `
        <ul>
            <li>
                Усиление способности к анализу, за счет тренировки расчета вариантов на более сложном уровне.        
            </li>
            <li>
                Развитие памяти, за счет изучения дебютных вариантов, и сложных окончаний.        
            </li>
            <li>
                Формирования способности к самоанализу, за счет осмысления своих партий, и анализа допущенных ошибок.            
            </li>
            <li>
                Знакомство со стратегическим мышлениям, за счет решения задач которые не решаются аналитическим путем, требуют оценочных суждений.
            </li>
            <li>
                Усиление спортивного характера, за счет постановки и достижения спортивных целей.
            </li>
        </ul>    
        <p>
            Стоимость: тренер ММ, абонемент на 8 занятий - 6000 руб. 
        </p>    
`
    },
    {
        googleActionName: 'stars_4',
        yandexActionName: 'banner_stars_4',
        image: require('./images/opt/c-4.jpg'),
        selImage: require('./images/opt/c-4.jpg'),
        name: 'Звезды 4-й год <br/> (рейтинг 2100-2300)',
        selName: 'Звезды <br/> 4-й год  (рейтинг 2100-2300)',
        bgColor: '#FFC02C',
        tileColor: '#A36EFF',
        content: `
            <ul>
                <li>
                    Определение индивидуальных особенностей шахматиста, и плана развития.
                </li>
                <li>
                    Для курирования процесса подготовки выстраивается контакт с лучшими тренерами академии. Регулярное общение с гроссмейстерами на видео-конференциях.
                </li>
                <li>
                    Усиление аналитических способностей, за счет тренировки сложных расчетов.
                </li>
                <li>
                    Развитие стратегического мышления, умения оценивать сложные ситуации.
                </li>
                <li>
                    Развитие памяти, за счет изучения сложных дебютных вариантов и сложных окончаний.\`
                </li>
            </ul>
            <p>
                Цена обсуждается индивидуально
            </p>    
        `
    }

]

export default function BounceSection(props) {
    const [visibleNumber, setVisibleNumber] = useState(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    let selectedItem = (visibleNumber == undefined) ? undefined : ITEMS[visibleNumber];

    return (
        <Wrapper id={'courses'}>

            <InnerPlaceholder className={'section-about__body'}>
                <Heading>
                    {/*Шахматные занятия для детей*/}
                    Обучение шахматам для детей
                </Heading>

                <TripletsPlaceholder>
                    <ItemsPlaceholder>
                        {ITEMS.map((a, i) => {
                            return (
                                <Item key={`a_${i}`} className={'course-tile'} onClick={() => {
                                    setVisibleNumber(i);
                                    try {
                                        AnalyticsAPI.reachGoal(a.yandexActionName);
                                        AnalyticsAPI.googleTrackEvent('banner', a.googleActionName);
                                    } catch (exc) {

                                    }
                                }}>
                                    <ItemTopImage src={a.image}/>
                                    <ItemBottomPlaceholder bgColor={a.bgColor}>
                                        {/*{a.name.replace(/<br\/>/g, '')}*/}
                                        <div dangerouslySetInnerHTML={{__html: a.name}}></div>
                                        <ClickImg src={require('./images/click.svg')}/>
                                    </ItemBottomPlaceholder>
                                </Item>
                            )
                        })}
                    </ItemsPlaceholder>
                </TripletsPlaceholder>


            </InnerPlaceholder>


            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setVisibleNumber(undefined);
                }}>

                    <div className={'it_sel_n_m'}>
                        {/*<SelImage src={selectedItem.selImage}/>*/}

                        <SelHeading>
                            <div dangerouslySetInnerHTML={{__html: selectedItem.selName}}></div>
                        </SelHeading>

                        <SelText>
                            <div dangerouslySetInnerHTML={{__html: selectedItem.content}}></div>
                        </SelText>

                        <BottomButtonPlaceholder>
                            {/*<UpdateLeadForm*/}
                            {/*    buttonName={'Записаться'}*/}
                            {/*    hasHeading={false}*/}
                            {/*    googleActionName={selectedItem.googleActionName}*/}
                            {/*    yandexActionName={`${selectedItem.yandexActionName}`.replace('form', 'button')}*/}
                            {/*/>*/}

                            <RedActionButton onClick={() => {
                                AnalyticsAPI.reachGoal(`button_trial_lesson_${selectedItem.yandexActionName}`);
                                AnalyticsAPI.googleTrackEvent('button', `trial_lesson_${selectedItem.googleActionName}`);
                                LocationHelper.navigateToLeadForm();
                                setVisibleNumber(undefined);
                            }}>
                                ЗАПИСАТЬСЯ НА ПРОБНОЕ ЗАНЯТИЕ
                            </RedActionButton>

                            {/*<RedButton onClick={() => {*/}
                            {/*setModalVisible(true);*/}
                            {/*}} >*/}
                            {/*БЕСПЛАТНОЕ ПРОБНОЕ ЗАНЯТИЕ*/}
                            {/*</RedButton>*/}
                        </BottomButtonPlaceholder>


                    </div>

                </NiceModal>
            }

            {/*{modalVisible == false ? null :*/}
            {/*    <NiceModal onClose={() => {*/}
            {/*        setModalVisible(false);*/}
            {/*    }}>*/}

            {/*        <UpdateLeadForm/>*/}

            {/*    </NiceModal>*/}
            {/*}*/}

        </Wrapper>
    );
}

const breakWidth = 1100;

const RedActionButton = styled.div`
  height: 44px;
  border-radius: 1000px;
  background: #FF624F;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const BottomButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 60px;
  justify-content: center;
`;

const ClickImg = styled.img`
  width: 26px;
  height: 26px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0.8;
`;

const RedButton = styled.div`
  height: 44px;
  border-radius: 1000px;
  background: #FF624F;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const SelImage = styled.img`
  border-radius: 20px;
  width: 100%;
  display: block;
  margin-bottom: 80px;
`;

const SelHeading = styled.div`
  margin-bottom: 28px;
  font-weight: 800;
  line-height: 1.142;
  font-size: 35px;
  text-align: left;
  @media (max-width: 812px) {
    font-size: 22px;
    padding-top: 42px;
  }
`;

const SelText = styled.div`
  font-weight: inherit;
  line-height: 1.56;
  font-size: 16px;
  color: inherit;
  font: 600 16px/1.56 "Open Sans", sans-serif;
  @media (max-width: 812px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Item = styled.div`
  width: 25%;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  height: 400px;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }

  @media (max-width: 920px) {
    width: 33.33%;
  }
  @media (max-width: 820px) {
    width: 50%;
  }
  @media (max-width: 620px) {
    width: 100%;
  }
`;

const ItemTopImage = styled.div`
  height: 270px;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const ItemBottomPlaceholder = styled.div`
  box-sizing: border-box;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 100px;
  padding: 20px;
  font-weight: 800;
  line-height: 1.25;
  font-size: 20px;
  margin-bottom: 18px;
  background-color: ${props => props.bgColor};
  height: 130px;
  position: relative;
`;


const ItemImage = styled.img`
  height: 80px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
`;


const ImagePlaceholder = styled.div`
  margin-bottom: 30px;
  position: relative;
  height: 120px;
`;

const ItemInner = styled.div`
  padding: 5px;
  box-sizing: border-box;
`;

const ItemName = styled.div`
  font-weight: 800;
  line-height: 1.25;
  font-size: 20px;
  margin: 0 0 20px;
`;


const ItemText = styled.div`
  font: 600 16px/1.56 "Open Sans", sans-serif;
  color: #000;
`;

const ItemsPlaceholder = styled.div`

`;

const TripletsPlaceholder = styled.div`
  margin-top: 40px;
`;

const MainText = styled.div`
  font: 600 20px/1.56 "Open Sans", sans-serif;
  color: #000;
  width: 600px;
`;

const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  ::before {
    content: '';
    width: 1em;
    height: 1em;
    position: absolute;
    top: -96px;
    right: -154px;
    z-index: -1;
    background-image: url(${aboutImg});
    background-size: cover;
    font-size: 246px;
    transform: rotate(30deg);
  }

  @media (max-width: ${breakWidth}px) {
    width: 100%;
    padding: 20px;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  box-sizing: border-box;
  background: white;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 812px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const Heading = styled.h2`
  font-size: 35px;
  margin-bottom: 44px;
  text-align: center;
  @media (max-width: 812px) {
    font-size: 26px;
    margin-bottom: 14px;
  }
`;
