/**
 * Created by sabir on 28.11.17.
 */

import ym from 'react-yandex-metrika';


const AnalyticsAPI = {



    reachGoal(goalName, parametersMap = {}) {
        console.log('reachGoal occured: goalName, parametersMap = ', goalName, parametersMap);
        try {
            window.ym(65403928, 'reachGoal', goalName, parametersMap);
            console.log('reachGoal: goalName, parametersMap = ', goalName, parametersMap);
        } catch (exc) {
            console.log('can not reach goal: exc = ', exc);
        }
    },


    pixelTrackLead() {
        try {
            window.fbq('track', 'Lead');
        } catch (exc) {
            console.log('can not track lead: exc = ', exc);
        }
    },

    googleTrackEvent(type = 'button', name = 'Send_Form') {
        try {
            window.ga('send', 'event', type, name);
        } catch (exc) {

        }
    },

    trackPhoneClick() {
        this.reachGoal('Phone');
        this.googleTrackEvent('button', 'Phone');
    },

    trackTelegramClick() {
        this.reachGoal('Telegram'); // Yandex
        this.googleTrackEvent('button', 'Telegram'); // Google
    },

    trackWhatsAppClick() {
        this.reachGoal('WhatsApp');  // Yandex
        this.googleTrackEvent('button', 'WhatsApp'); // Google
    },

    trackFormPhoneClick() {
        this.reachGoal('Phone_Form');
        this.googleTrackEvent('button', 'Phone_Form');
    },


    trackFormOpen() {
        this.reachGoal('Open_Form');
        this.googleTrackEvent('button', 'Open_Form');
    },

    trackSendOpen() {
        this.reachGoal('Send_Form');
        this.googleTrackEvent('button', 'Send_Form');
    },


}

// module.exports = AnalyticsAPI;
export default AnalyticsAPI;
