import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'
import NiceModal from "../modals/NiceModal";
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import LocationHelper from "../helpers/LocationHelper";

const ITEMS = [
    // {
    //     isBig: true,
    //     image: require('./images/romanov-tr.jpg'),
    //     name: 'Евгений Романов',
    //     text: 'Руководитель представительства в Санкт-Петербурге и Ленинградской области. Международный гроссмейстер. Mаксимальный рейтинг ФИДЕ 2662. Тренер Олимпийских сборных Турции, Германии, Грузии и Норвегии.',
    //     content: `
    //     <ul>
    //         <li>
    //             Бронзовый призер Чемпионата Европы
    //         </li>
    //         <li>
    //             Бронзовый призёр Олимпиады 2018 в качестве тренера женской сборной Грузии.
    //         </li>
    //         <li>
    //             Многолетний тренер-секундант Чемпиона мира до 20 лет (2017г.) ГМ Арьяна Тари
    //         </li>
    //     </ul>`,
    //     city: 'default'
    // },
    {
        isBig: true,
        image: require('./images/tr-2.jpg'),
        name: 'Михаил Можаров',
        text: 'Международный гроссмейстер, максимальный рейтинг ФИДЕ 2573. Тренер Эрнесто Инаркиева.',
        content: `
        <ul>
            <li>
                Победитель турнира «B», в рамках одного их сильнейших фестивалей в мире «Аэрофлот-опен». (г.Москва, 2018г.)
            </li>
            <li>
                Чемпион Московской области среди юношей до 12 лет (2001 г.);
            </li>
            <li>
                Чемпион России среди детских коллективов в составе СДЮШОР им. Т.В. Петросяна (г. Нижний Новгород, 2006 г.);
            </li>
            <li>
                Победитель Всероссийской спартакиады школьников в составе юношеской сборной Москвы (г. Казань, 2007 г.);
            </li>
            <li>
                Чемпион Москвы по блицу среди юношей до 18 лет (2007 г.);
            </li>
            <li>
                Обладатель Кубка Мэра г. Москвы (2014 г.).
            </li>
            <li>
                Победитель международного турнира (г.Плайя Д’Аро, 2016г.)
            </li>
        </ul>`,
        city: 'default'
    },

    // {
    //     image: require('./images/tr-3.jpg'),
    //     name: 'Георгий Нагибин',
    //     text: 'Международный мастер с 2009 г.<br/> Опыт работы тренером 15 лет.',
    //     content: `
    //         <p>
    //             Образование высшее, закончил НИЯУ МИФИ, факультет экспериментальной и теоретической физики, специальность – медицинская физика в 2012 г.
    //         </p>
    //         <ul>
    //             <li>
    //                 Руководитель благотворительного проекта  федерации шахмат России  «Шахматы в детские дома», 2015–2018 гг.
    //             </li>
    //             <li>
    //                 Член Наблюдательного совета федерации шахмат России 2018 г.
    //             </li>
    //         </ul>
    //     `,
    //     city: 'default'
    // },

    {
        isBig: true,
        image: require('./images/schekachihin.jpg'),
        name: 'Максим Щекачихин',
        text: 'Международный мастер с 2018 г.<br/> Опыт работы тренером 4 года.',
        content: `
        <ul>
            <li>
                Победитель Универсиады, Хорватия, 2019
            </li>
            <li>
                Участник мужских чемпионатов Европы по шахматам 2017, 2018 года.
            </li>
            <li>
                В составе команды Санкт-Петербурга выигрывал Высшую лигу чемпионата России-2018 и играл на первой доске в Российской Премьер Лиге
            </li>
        </ul>
        <p style="margin-top: 20px;" >
            Успехи учеников:
        </p>
        <ul>
            <li>
                Призеры детских первенств Санкт-Петербурга и Москвы по классическим шахматам.
            </li>
            <li>
                Призеры и победителями первенств Санкт-Петербурга по быстрым шахматам и блицу.
            </li>
            <li>
                Призеры и победители детских этапов Кубка России
            </li>
        </ul>
        
        `,
        city: 'spb'
    },

    {
        image: require('./images/hakimov.jpg'),
        name: 'Тимур Хакимов',
        text: 'Международный мастер.<br/> Опыт работы тренером 4.5 года.',
        content: `
        <ul>
            <li>
                Победитель и призер детских первенств Краснодарского края, Республики Башкортостан.
            </li>
            <li>
                Победитель и призер нескольких всероссийских и международных турниров.
            </li>
        </ul>
        
         <p style="margin-top: 20px;" >
            Успехи учеников:
        </p>
        <ul>
            <li>
                Большинство учеников выполнили разряды. 
            </li>
            <li>
                Среди учеников — победитель полуфинала первенства Санкт-Петербурга в возрасте до 12 лет, участник финалов первенства Санкт-Петербурга в своем возрасте.
            </li>
        </ul>
        `,
        city: 'spb'
    },

    {
        image: require('./images/tr-1.jpg'),
        name: 'Алёна Строгова',
        text: 'Кандидат в мастера спорта. <br/>Опыт работы тренером 6 лет',
        content: `<ul>
            <li>
                Многократная чемпионка Ростовской области
            </li>
            <li>
                Четырехкратная чемпионка южно-федерального округа
            </li>
            <li>
                Ежегодная участница чемпионатов России
            </li>
        </ul>`,
        city: 'default'
    },
    {
        image: require('./images/valeria.jpeg'),
        name: 'Валерия Воробьева',
        text: 'Кандидат в мастера спорта. <br/> Опыт работы тренером 2 года.',
        content: `
        <ul>
            <li>
                Неоднократная чемпионка Санкт-Петербурга по классическим шахматам
            </li>
            <li>
                Член юниорской сборной Санкт-Петербурга.  
            </li>
            <li>
                Неоднократная участница и призер международных соревнований (Финляндия, Чехия, Хорватия, Латвия). 
            </li>
            <li>
                Победитель универсиады в Хорватии 2019 года в составе сборной команды по шахматам Горного Университета.
            </li>
        </ul>
        `,
        city: 'spb'
    },
];


export default function TrainersSection(props) {
    const [selectedNumber, setSelectedNumber] = useState(undefined);
    let selectedItem = (selectedNumber == undefined) ? undefined : ITEMS[selectedNumber];

    return (
        <Wrapper>

            <InnerPlaceholder>

                {/*<Heading>*/}
                {/*Лучшие тренеры*/}
                {/*</Heading>*/}

                <ItemsPlaceholder>
                    {ITEMS.map((a, i) => {
                        return (
                            <Item key={`a_${i}`} big={a.isBig} onClick={() => {
                                setSelectedNumber(i);
                            }}>
                                <ItemInner>
                                    <ItemImage src={a.image} big={a.isBig}/>
                                    <ItemName>
                                        {a.name}
                                    </ItemName>
                                    <ItemText>
                                        <div dangerouslySetInnerHTML={{__html: a.text}}></div>
                                    </ItemText>
                                </ItemInner>
                            </Item>
                        )
                    })}
                </ItemsPlaceholder>

            </InnerPlaceholder>

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedNumber(undefined);
                }}>

                    <div className={'it_sel_n_m'}>
                        {/*<SelImage src={selectedItem.selImage}/>*/}

                        <SelAvatarPlaceholder>
                            <SelAvatar src={selectedItem.image}/>
                        </SelAvatarPlaceholder>

                        <SelHeading>
                            <div dangerouslySetInnerHTML={{__html: selectedItem.name}}></div>
                        </SelHeading>

                        {!(selectedItem.city == 'spb' && LocationHelper.isSpb() == true) ? null :
                            <SpbHeading>
                                {'Санкт-Петербург'}
                            </SpbHeading>
                        }


                        <SelText>
                            <div dangerouslySetInnerHTML={{__html: selectedItem.content}}></div>
                        </SelText>

                        <BottomButtonPlaceholder>
                            <UpdateLeadForm
                                buttonName={'Записаться'}
                                hasHeading={false}/>
                        </BottomButtonPlaceholder>

                    </div>

                </NiceModal>
            }

        </Wrapper>
    );
}

const SelAvatarPlaceholder = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SelAvatar = styled.div`
    width: 160px;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
    border-radius: 1000px;
`;

const breakWidth = 1100;

const BottomButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 60px;
    justify-content: center;
`;


const ItemImage = styled.img`
    height: ${props => (props.big == true ? 280 : 160)}px;
    border-radius: 1000px;
`;


const SelHeading = styled.div`
    margin-bottom: 28px;
    font-weight: 800;
    line-height: 1.142;
    font-size: 35px;
    text-align: center;
    @media(max-width: 812px){
      font-size: 22px;
      padding-top: 42px;
    }
`;

const SpbHeading = styled.div`
    margin-bottom: 28px;
    font-weight: 800;
    line-height: 1.142;
    font-size: 30px;
    text-align: center;
    margin-top: -10px;
    opacity: 0.7;
    @media(max-width: 812px){
      font-size: 22px;
      padding-top: 42px;
    }
`;


const SelText = styled.div`
    font-weight: inherit;
    line-height: 1.56;
    font-size: 16px;
    color: inherit;
    font: 600 16px/1.56 "Open Sans",sans-serif;
    @media(max-width: 812px){
      font-size: 14px;
      line-height: 20px;
    }
`;

const Item = styled.div`
    width: ${props => (props.big == true ? 50 : 33.33)}%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
    cursor: pointer;
    
    :hover{
      opacity: 0.8;
    }
    
    @media(max-width: 920px){
      width: 50%;
    }
    @media(max-width: 620px){
      width: 100%;
    }
`;

const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 120px;
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const ItemName = styled.div`
    font-weight: 800;
    line-height: 1.25;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
`;

const ItemText = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    font-weight: normal;
    font-style: italic;
    color: #939598;
    text-align: center;
`;

const ItemsPlaceholder = styled.div`
    text-align: center;
`;


const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    //padding-top: 60px;
    //padding-bottom: 60px;
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 20px;
    }
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: center;
`;

