import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

const TESTIMONIALS = [
    {
        name: 'Алсу',
        title: 'Мама, двух сыновей:<br/> 8 лет (1-й год обучения) и 5 лет (младшая группа).',
        comment: `Удобный формат занятий онлайн. Не нужно ребенка специально приводить, ждать. При этом, получаешь доступ и к сильным тренерам, и к лучшим методикам - это очень удобно. С таким высоким качеством, порадовала цена. Оба сына занимаются с удовольствием. Старший уже занимался шахматами ранее, но занятия были не такого высокого уровня, и в итоге он потерял интерес. Сейчас, у обоих сыновей искренний интерес к шахматам, вижу что интеллектуально развиваются - это очень здорово. Рады, что  оказались в числе первых учеников ШАГИ CHESS.`
    },
    {
        name: 'Илья',
        title: 'Участник вебинаров.<br/> <br/>',
        comment: `Очень положительные впечатления от вебинаров! Материалы высшего качества, очень интересно послушать и поучаствовать. Живое общение с гроссмейстерами высокого уровня за такую цену - это очень круто! Занятие в группе даже лучше, так как есть с кем сравнить свои мысли и результаты. 
Более 20 лет увлекаюсь шахматами, и понимаю, что такое занятие стоит намного дороже. Рад что в ШАГИ CHESS можно позаниматься с гроссмейстерами за такую цену.
`
    },
    {
        name: 'Aнна',
        title: 'Мама, сын 6 лет занимается в младшей группе.<br/><br/>',
        comment: `Очень сильный тренер, хорошо общается с детьми и грамотно строит занятие, вовремя делает перерывы.
Приятно удивлена, что дети могут хорошо заниматься в группе через интернет.
`
    }

]

export default function TestimonialsSection(props) {
    const [cursor, setCursor] = useState(0);
    const visibleItems = [TESTIMONIALS[cursor % TESTIMONIALS.length], TESTIMONIALS[(+cursor + 1) % TESTIMONIALS.length]];
    console.log('visibleItems = ', visibleItems);

    return (
        <Wrapper>

            <Heading>
                Отзывы
            </Heading>

            <InnerWrapper>

                <Side>
                    <ArrowDiv style={{marginRight: -10}}>
                        <ArrowImg
                            onClick={() => {
                                let prev = (+cursor + TESTIMONIALS.length - 1) % TESTIMONIALS.length;
                                setCursor(prev);
                            }}
                            src={require('./images/next.svg')}
                            style={{transform: `rotate(180deg)`}}
                        />
                    </ArrowDiv>
                </Side>

                {visibleItems.map((a, k) => {
                    return (
                        <Box key={`${a.name}_${k}`}>
                            <BoxName>
                                {a.name}
                            </BoxName>
                            <BoxTitle>
                                <div dangerouslySetInnerHTML={{__html: a.title}}></div>
                            </BoxTitle>
                            <BoxComment>
                                {a.comment}
                            </BoxComment>
                        </Box>
                    )
                })}

                <Side>
                    <ArrowDiv style={{marginLeft: -10}} onClick={() => {
                        let next = (+cursor + 1) % TESTIMONIALS.length;
                        setCursor(next);
                    }}>
                        <ArrowImg src={require('./images/next.svg')}

                        />
                    </ArrowDiv>
                </Side>

            </InnerWrapper>


        </Wrapper>
    );
}

const breakWidth = 1100;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: center;
     @media(max-width: 820px){
      margin-bottom: 14px;
    }
`;


const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
    @media(max-width: 812px){
      padding-top: 0px;
      margin-bottom: 0px;
    }
`;


const InnerWrapper = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 20px;
    }
    

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
    @media(max-width: 820px){
      display: block;
    }
`;

const Side = styled.div`
    width: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: pink;
    position: relative;
    @media(max-width: 820px){
      display: none;
    }
`;

const ArrowDiv = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 1000px;
    background: #ffc02c;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const ArrowImg = styled.img`
    width: 12px;
    height: 12px;
    margin-bottom: 0px;
`;

const Box = styled.div`
    text-align: center;
    flex: 1;
    background: white;
    padding: 20px;
    border-radius: 20px;
    color: black;
    margin: 10px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0px 5px 10px rgba(31,32,65,0.05);
    min-height: 140px;
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
`;

const BoxTitle = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    opacity: 0.6;
`;


const BoxComment = styled.div`
    text-align: center;
    font-size: 14px;
    opacity: 0.5;
    font-style: italic;
`;
