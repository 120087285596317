const LocationHelper = {

    isSpb() {
        try {
            return (window.location.href.indexOf('spb') > -1);
        } catch (e) {
            return false;
        }
    },

    getPhoneLabel() {
        if (this.isSpb()) {
            return SPB_PHONE_LABEL;
        } else {
            return DEFAULT_PHONE_LABEL
        }
    },

    getPhone() {
        if (this.isSpb()) {
            return SPB_PHONE;
        } else {
            return DEFAULT_PHONE;
        }
    },

    getWhatsapPhone() {
        return '+79035594474';
        // if (this.isSpb()) {
        //     return SPB_PHONE;
        // } else {
        //     return DEFAULT_PHONE;
        // }
    },

    navigateToLeadForm() {
        try{
            let element = document.getElementById('new_lead_form');
            element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            setTimeout(() => {
                let el1 = document.getElementById('new_lead_form_name_input');
                el1.focus();
            }, 700);
        }catch(exc){

        }
    }


}

// +7 (800) 770-71-62.

// const SPB_PHONE_LABEL = '+7 (968) 550-68-80';
// const SPB_PHONE = '+79685506880';

const SPB_PHONE_LABEL = '+7 (800) 770-71-62';
const SPB_PHONE = '+78007707162';


// const DEFAULT_PHONE_LABEL = '+7 (903) 559-44-74';
// const DEFAULT_PHONE = '+79035594474';

const DEFAULT_PHONE_LABEL = '+7 (800) 770-71-62';
const DEFAULT_PHONE = '+78007707162';


export default LocationHelper;
