import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'

const ernImg = require('./images/ernesto_opt.jpg')
const tileImg = require('./images/blue_tile.svg')
const hImg = require('./images/head.svg')


export default function VideoSection(props) {


    return (
        <Wrapper>

            <InnerPlaceholder className={'section-about__body'}>

                <VideoPlaceholder>
                    <VideoIframe src="https://www.youtube.com/embed/wccSfT8Y2Ls"
                                 frameborder="0"
                                 allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                 allowfullscreen></VideoIframe>

                </VideoPlaceholder>

            </InnerPlaceholder>


        </Wrapper>
    );
}

const breakWidth = 1100;

const InnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  box-sizing: border-box;
  background: white;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 720px) {
    display: none;
  }
`;

const VideoPlaceholder = styled.div`
  border: none;
  width: ${breakWidth * 0.8}px;
  height: ${breakWidth * 0.8 * 9.0 / 16.0}px;
  position: relative;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }

  ::before {
    content: '';
    width: 1em;
    height: 1em;
    position: absolute;

    z-index: 1;
    background-image: url(${hImg});
    background-size: cover;
    transform: rotate(-15deg);
    bottom: -72px;
    left: -210px;
    font-size: 482px;
  }
`;

const VideoIframe = styled.iframe`
  border: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;
