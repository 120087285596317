import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import NiceModal from "../modals/NiceModal";
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import AnalyticsAPI from "../api/AnalyticsAPI";
import LocationHelper from "../helpers/LocationHelper";

import teleImg from './images/Telegram.png'
import whatsImg from './images/WhatsApp.png'

const scrollToDiv = (divId) => {
    try {
        let elmnt = document.getElementById(divId);
        elmnt.scrollIntoView({behavior: "smooth"});
    } catch (e) {

    }
}

const bgImage = require('./images/banner.jpg')


export default function TopSection(props) {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <Wrapper>

            <TopHeader>
                <LogoImg src={require('./images/logo.svg')}/>

                <MobilePhonePlaceholder onClick={() => {
                    AnalyticsAPI.trackPhoneClick();
                }} href={`tel:${LocationHelper.getPhone()}`}>
                    {`${LocationHelper.getPhoneLabel()}`}
                </MobilePhonePlaceholder>

                <LinksPlaceholder>
                    {/*<LinkItem>*/}
                    {/*для кого*/}
                    {/*</LinkItem>*/}

                    {/*<LinkItem onClick={() => {*/}
                    {/*    scrollToDiv('courses');*/}
                    {/*}}>*/}
                    {/*    обучение*/}
                    {/*</LinkItem>*/}

                    {/*<LinkItem onClick={() => {*/}
                    {/*scrollToDiv('webinars');*/}
                    {/*}}>*/}
                    {/*вебинары*/}
                    {/*</LinkItem>*/}

                    <SocLinksDiv>
                        <SocLink href={'https://t.me/Chess_Academy_Shagi'} target={'_blank'} onClick={() => {
                            AnalyticsAPI.trackTelegramClick();
                        }}>
                            <SocLinkImg src={teleImg}/>
                        </SocLink>

                        <SocLink
                            href={`https://api.whatsapp.com/send?phone=${LocationHelper.getWhatsapPhone().replace('+', '')}&text=Добрый день! Хочу записаться на пробное занятие.`}
                            target={'_blank'}
                            onClick={() => {
                                AnalyticsAPI.trackWhatsAppClick();
                            }}
                        >
                            <SocLinkImg src={whatsImg}/>
                        </SocLink>
                    </SocLinksDiv>

                    <PhoneLinkItem href={`javascript:void(0);`} onClick={() => {
                        AnalyticsAPI.trackPhoneClick();
                    }}>
                        {`${LocationHelper.getPhoneLabel()}`}
                    </PhoneLinkItem>

                    {/*<LinkItem onClick={() => {*/}
                    {/*    scrollToDiv('about');*/}
                    {/*}}>*/}
                    {/*    о нас*/}
                    {/*</LinkItem>*/}


                    {/*<LinkItem style={{marginRight: 50}} >*/}
                    {/*вход*/}
                    {/*</LinkItem>*/}
                    {/*<LinkItem>*/}
                    {/*для тренеров*/}
                    {/*</LinkItem>*/}
                    <SignUpItem onClick={() => {
                        window.location.href = `/app/`;
                    }}>
                        {/*пробный урок*/}
                        вход
                    </SignUpItem>
                </LinksPlaceholder>
            </TopHeader>

            <ContentPlaceholder>
                <ContentInner>
                    <MainMottoPlaceholder>
                        <MainMotto>
                            {/*УЧИМ ШАХМАТАМ*/}
                            РАЗВИВАЕМ ИНТЕЛЛЕКТ
                            <br/>
                            {/*ОНЛАЙН*/}
                            УЧИМ ПОБЕЖДАТЬ
                        </MainMotto>
                        <BottomButtonPlaceholder>
                            <BlackButton onClick={() => {
                                // AnalyticsAPI.trackFormOpen();
                                AnalyticsAPI.googleTrackEvent('button', 'free_lesson');
                                AnalyticsAPI.reachGoal('button_free_lesson');
                                // setModalVisible(true);
                                LocationHelper.navigateToLeadForm();
                            }}>
                                {/*БЕСПЛАТНОЕ ЗАНЯТИЕ*/}
                                ПРОБНОЕ ЗАНЯТИЕ
                            </BlackButton>
                        </BottomButtonPlaceholder>
                    </MainMottoPlaceholder>
                </ContentInner>
            </ContentPlaceholder>

            {modalVisible == false ? null :
                <NiceModal onClose={() => {
                    setModalVisible(false);
                }}>

                    <UpdateLeadForm
                        googleActionName={'top'}
                        yandexActionName={'button_top'}
                    />

                </NiceModal>
            }

        </Wrapper>
    );
}

const SocLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 720px) {
    display: block;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 20px;
    padding-right: 120px;
  }
`;

const MobilePhonePlaceholder = styled.a`
  display: none;
  text-align: center;
  text-decoration: none;
  color: black;
  @media (max-width: 812px) {
    display: block;
    font-size: 28px;
    font-weight: bold;
  }
  @media (max-width: 320px) {
    display: block;
    font-size: 20px;
    font-weight: bold;
  }
`;

const Field = styled.div`

`;

const Input = styled.input`

`;

const ContentPlaceholder = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 1100px;
  height: 100%;
  @media (max-width: 820px) {
    justify-content: center;
    padding-top: 90px;
  }
  @media (max-width: 320px) {
    padding-top: 60px;
  }
`;

const MainMottoPlaceholder = styled.div`
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const MainMotto = styled.div`
  margin-bottom: 52px;
  font-size: 65px;
  color: black;
  line-height: 80px;
  font-weight: 800;
  @media (max-width: 812px) {
    width: 100%;
    text-align: center;
    font-size: 34px;
    line-height: 60px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 20px;
  }
  @media (max-width: 320px) {
    display: block;
    font-size: 26px;
    font-weight: bold;
    margin-top: 10px;
  }
`;

const LinkItem = styled.div`
  font: 600 22px/1.56 "Open Sans", sans-serif;
  font-weight: bold;
  color: black;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px dashed transparent;

  :hover {
    border-bottom: 2px dashed black;
  }

  @media (max-width: 1100px) {
    font: 600 18px/1.56 "Open Sans", sans-serif;
    font-weight: bold;
    color: black;
  }
  @media (max-width: 900px) {
    font: 600 14px/1.56 "Open Sans", sans-serif;
    font-weight: bold;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const SocLink = styled.a`
  margin-right: 20px;
  line-height: 20px;
`;

const SocLinkImg = styled.img`
  width: 40px;
  height: 40px;
`;

const PhoneLinkItem = styled.a`
  font: 600 22px/1.56 "Open Sans", sans-serif;
  font-weight: bold;
  color: black;
  margin-right: 20px;
  text-transform: uppercase;
  //cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px dashed transparent;
  text-decoration: none;

  :hover {
    //border-bottom: 2px dashed black;
  }

  @media (max-width: 1100px) {
    font: 600 18px/1.56 "Open Sans", sans-serif;
    font-weight: bold;
    color: black;
  }
  @media (max-width: 900px) {
    font: 600 14px/1.56 "Open Sans", sans-serif;
    font-weight: bold;
    color: black;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (max-width: 812px) {
    display: none;
  }
`;

const SignUpItem = styled.div`
  font: 600 22px/1.56 "Open Sans", sans-serif;
  font-weight: bold;
  box-sizing: border-box;
  border: 3px solid black;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 24px;
  height: 50px;
  line-height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: black;
  cursor: pointer;
  margin-left: 60px;

  :hover {
    background: white;
  }

  @media (max-width: 720px) {
    margin-left: 0px;
    margin-top: 20px;
    display: none;
  }
`;

const LinksPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 720px) {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${bgImage});
  font-family: "Open Sans", sans-serif;
`;

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 25px;
  @media (max-width: 1100px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: 720px) {
    display: block;
    padding: 20px;
    text-align: center;
  }
`;

const LogoImg = styled.img`
  height: 45px;
  margin-bottom: 0px;
`;

const BottomButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 720px) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

const BlackButton = styled.div`
  background: white;
  color: black;
  height: 60px;
  border-radius: 10px;
  //color: white;
  font-weight: bold;
  font-size: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 35px;
  padding-right: 35px;
  cursor: pointer;

  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.75);

  :hover {
    opacity: 0.95;
  }

  @media (max-width: 720px) {
    font-size: 20px;
  }
`;
