import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'
import UpdateLeadForm from "../lead/forms/UpdateLeadForm";
import NiceModal from "../modals/NiceModal";

import AnalyticsAPI from '../api/AnalyticsAPI'
import LocationHelper from "../helpers/LocationHelper";

const ITEMS = [
    {
        image: require('./images/vv_01.svg'),
        name: 'Записываемся на пробное занятие',
        text: 'Тренер проводит тестирование, дает рекомендации по подготовке к тренировкам.'
    },
    {
        image: require('./images/vv_02.svg'),
        name: 'Регистрируемся на портале',
        text: 'В личном кабинете проводятся занятия. Там же можно посмотреть расписание, материалы урока и домашнее задание.'
    },
    {
        image: require('./images/vv_03.svg'),
        name: 'Домашнее задание',
        text: 'Выполняем самостоятельно, в личном кабинете.'
    },
    {
        image: require('./images/vv_04.svg'),
        name: 'Статистика',
        text: 'Формируется на основании изученных материалов и решения упражнений. Показывает сильные и слабые стороны, над чем надо работать.'
    },
    {
        image: require('./images/vv_05.svg'),
        name: 'Анализ результатов',
        text: ' Если результаты по одной из тем хуже запланированных, тренер скорректирует тренировочную программу. Если прогресс идет очень быстро, попадаете в поле зрения ведущих тренеров.'
    },
    {
        image: require('./images/vv_06.svg'),
        name: 'Стабильный рост шахматного мастерства',
        text: 'Достигается за счет оптимального сочетание занятий с тренером и самостоятельных тренировок на портале.'
    },

]

export default function BenefitsSection(props) {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <Wrapper>

            <InnerPlaceholder>

                <Heading>
                    Как проходит обучение
                </Heading>

                <ItemsPlaceholder>
                    {ITEMS.map((a, i) => {
                        return (
                            <Item key={`a_${i}`}>
                                <ItemInner>
                                    <ImagePlaceholder className={`rel_im_it in_ben el_${i}`}>
                                        <ItemImage src={a.image}/>
                                    </ImagePlaceholder>
                                    <ItemName>
                                        {a.name}
                                    </ItemName>
                                    <ItemText>
                                        {a.text}
                                    </ItemText>
                                </ItemInner>
                            </Item>
                        )
                    })}
                </ItemsPlaceholder>

            </InnerPlaceholder>

            <BottomButtonPlaceholder>
                <RedButton onClick={() => {
                    AnalyticsAPI.trackFormOpen();
                    // setModalVisible(true);
                    LocationHelper.navigateToLeadForm();
                }}>
                    {/*БЕСПЛАТНОЕ ПРОБНОЕ ЗАНЯТИЕ*/}
                    {/*ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ*/}
                    ЗАПИСАТЬСЯ НА ПРОБНОЕ ЗАНЯТИЕ
                </RedButton>
            </BottomButtonPlaceholder>

            {modalVisible == false ? null :
                <NiceModal onClose={() => {
                    AnalyticsAPI.googleTrackEvent('form', 'howto');
                    AnalyticsAPI.reachGoal('form_howto');
                    setModalVisible(false);
                }}>

                    <UpdateLeadForm
                        googleActionName={'howto'}
                        yandexActionName={'button_howto'}
                    />

                </NiceModal>
            }


        </Wrapper>
    );
}

const RedButton = styled.div`
    height: 44px;
    border-radius: 1000px;
    background: #FF624F;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      font-size: 14px;
      padding-left: 15px;
      padding-right: 15px;
    }
`;

const BottomButtonPlaceholder = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media(max-width: 812px){
      padding-top: 0px;
      padding-bottom: 0px;
    }
`;

const breakWidth = 1100;

const ItemImage = styled.img`
    height: 90px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    @media(max-width: 820px){
      width: 100px;
      left: -50px;
    }
`;

const Item = styled.div`
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
    @media(max-width: 820px){
      width: 50%;
    }
    @media(max-width: 620px){
      width: 100%;
    }
`;

const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 90px;
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
    @media(max-width: 820px){
      display: flex;
      flex-direction: column;
      align-items: center;
    }
`;

const ItemName = styled.div`
    font-weight: 800;
    line-height: 1.25;
    font-size: 20px;
    margin: 0 0 20px;
`;

const ItemText = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    color: #000;
`;

const ItemsPlaceholder = styled.div`
    
`;


const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
    @media(max-width: 812px){
      padding-top: 10px;
      padding-bottom: 10px;
    }
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: ${breakWidth}px){
      width: 100%;
      padding: 20px;
    }
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: center;
     @media(max-width: 812px){
      font-size: 26px;
      margin-bottom: 24px;
     }
`;

